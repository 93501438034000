export default {
    data() {
        return {
            red: '#FE3C3C',
            blue: '#4468E1',
            green: '#46C07D',

            // chart对象
            myChart: null,

            message: '',

            // 记录哪些节点点击过
            record: {},
            chartIndex: 0,

            canvasData: {
                show: false,
                title: '',
                api: '',
                field: '',
                baseQuery: {},
                showKey: '',
            },
            option: {},

            nodes: [],
            lines: [],
        }
    },
    mounted() {
        if (this.isRight) {
            if (!this.$route.query.txt) {
                this.init()
            }
        } else {
            this.init()
        }
        this.canvasData.fieldShowList = this.fieldShowList
    },
    methods: {
        init() {
            this.record[this.corp.id] = true
            const firstQuery = {}
            firstQuery[this.firstKey] = this.corp[this.firstKey]
            this.nodes = [
                {
                    id: this.corp.id,
                    name: this.corp[this.firstKey],
                    category: 0,
                    value: 0,
                    query: firstQuery
                }
            ]
            this.myChart = window.echarts.init(document.getElementById(this.key))
            this.loadChartEvent()
            // this.myChart.showLoading()

            this.$api.post(this.secondApi, this.getParam(firstQuery, this.secondQtyKey), {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.code !== '00000') {
                        this.message = 405
                    } else {
                        if(res.data.records && res.data.records.length > 0) {

                            this.canvasData.show = false
                            const baseQuery = {}
                            baseQuery[this.firstKey] = this.corp[this.firstKey]
                            this.canvasData.baseQuery = baseQuery
                            this.canvasData.api = this.secondApi
                            this.canvasData.field = this.secondKey
                            this.canvasData.title = this.corp[this.firstKey]
                            this.canvasData.showKey = this.secondQtyKey
                            this.$nextTick(() => {
                                this.canvasData.show = true
                            })

                            res.data.records.forEach(item => {
                                const id = this.getUuid()

                                // 构造查询
                                const query = {}
                                query[this.secondKey] = item[this.secondKey]

                                this.nodes[0].value = res.data.totalCount
                                this.nodes.push({
                                    id: id,
                                    name: item[this.secondKey],
                                    category: 1,
                                    value: this.secondQtyKey ? item[this.secondQtyKey] : '0',
                                    query,
                                    parentId: this.corp.id,
                                })

                                this.lines.push({
                                    source: this.corp.id,
                                    target: id
                                })
                            })
                        }
                        this.loadChart()
                    }
                })
        },
        getParam(obj, sortField) {
            const sort = {}
            if (sortField && sortField !== 'compNameCount') {
                sort[sortField] = 'desc'
            }
            return Object.assign({
                page: {
                    pageNo: 1,
                    pageSize: 20,
                },
                sort,
            }, {params: {...obj}})
        },
        getUuid() {
            this.chartIndex += 1
            return `chartIndex${this.chartIndex + 1}`
        },
        getRedCategory(obj = {}) {
            return Object.assign({
                name: 'first',
                label: {
                    show: true,
                    borderColor: this.red,
                    formatter: [
                        '{b} {assist|{c}}',
                    ].join('\n'),
                    rich: {
                        assist: {
                            color: '#fff',
                            backgroundColor: this.red,
                            lineHeight: 16,
                            fontSize: 12,
                            borderRadius: 10,
                            padding: [4, 12],
                        }
                    }
                },
                symbol: `image://${window.location.origin}/source/ball-red.png`
            }, obj)
        },
        getBlueCategory(obj = {}) {
            return Object.assign({
                name: 'second',
                label: {
                    show: true,
                    borderColor: this.blue,
                    formatter: [
                        this.secondQtyKey ? '{b} {assist|{c}}' : '{b}',
                    ].join('\n'),
                    rich: {
                        assist: {
                            color: '#fff',
                            backgroundColor: this.blue,
                            lineHeight: 16,
                            fontSize: 12,
                            borderRadius: 10,
                            padding: [4, 12],
                        }
                    }
                },
                symbol: `image://${window.location.origin}/source/ball-blue.png`
            }, obj)
        },
        getGreenCategory(obj = {}) {
            return Object.assign({
                name: 'third',
                label: {
                    show: true,
                    borderColor: this.green,
                    formatter: [
                        this.thirdQtyKey ? '{b}/{c}' : '{b}',
                    ].join('\n'),
                },
                symbol: `image://${window.location.origin}/source/ball-green.png`
            }, obj)
        },
        getCommonCategory() {
            return [
                this.getRedCategory(),
                this.getBlueCategory(),
                this.getGreenCategory(),
            ]
        },

        loadChart() {
            // this.myChart.clear()
            this.myChart.setOption({
                series: [
                    {
                        type: 'graph',
                        layout: 'force',
                        symbolSize: 60,
                        data: this.nodes,
                        links: this.lines,
                        categories: this.getCommonCategory(),
                        roam: 'move',
                        label: {
                            show: true,
                            position: 'top',
                            distance: -25,
                            padding: [0, 12, 0, 12],
                            lineHeight: 30,
                            borderRadius: 6,
                            borderWidth: 1,
                            color: '#333',
                            backgroundColor: '#fff',
                        },
                        force: {
                            repulsion: 2000,
                            friction: 0.3,
                            // edgeLength: [150, 200],
                            layoutAnimation: true,
                        },
                    }
                ]
            })
            this.myChart.hideLoading()
        },
        reloadCanvasData() {
            this.canvasData.show = false
            this.$nextTick(() => {
                this.canvasData.show = true
            })

        },
        loadChartEvent() {
            this.myChart.off('click').on('click', obj => {
                if (!this.record[obj.data.id]) {
                    if(obj.data.category === 1) {

                        // this.myChart.showLoading()
                        this.record[obj.data.id] = true

                        const baseQuery = {}
                        baseQuery[this.secondKey] = obj.data.name
                        this.$api.post(this.thirdApi, this.getParam(baseQuery, this.secondQtyKey))
                            .then(res => {
                                if(res.data.records && res.data.records.length > 0) {
                                    res.data.records.forEach(item => {
                                        const id = this.getUuid()
                                        this.nodes.push({
                                            id: id,
                                            name: item[this.thirdKey],
                                            category: 2,
                                            value: item[this.thirdQtyKey] || '0',
                                            query: baseQuery,
                                            parentId: obj.data.id,
                                        })

                                        // 给父级的qty赋值
                                        // if (this.secondQtyKey) {
                                        //
                                        // }

                                        this.lines.push({
                                            source: obj.data.id,
                                            target: id,
                                            lineStyle: {
                                                type: 'dashed'
                                            },
                                        })
                                    })
                                    this.loadChart()

                                }
                            })
                            .finally(() => {
                                this.myChart.hideLoading()
                            })
                    }
                }  else {
                    // 点击缩回
                    if (obj.data.category !== 0) {
                        const id = obj.data.id
                        this.nodes = this.nodes.filter(item => item.parentId !== id)
                        this.lines = this.lines.filter(item => item.source !== id)
                        this.record[id] = undefined
                        this.loadChart()
                    }
                }


                if(obj.data.category === 1) {
                    this.canvasData.baseQuery = obj.data.query
                    this.canvasData.api = this.thirdApi
                    this.canvasData.field = this.thirdKey
                    this.canvasData.title = `${obj.data.name}`
                    this.canvasData.showKey = this.thirdQtyKey

                    this.reloadCanvasData()
                }
                if (obj.data.category === 0) {
                    this.canvasData.show = false
                    this.canvasData.baseQuery = obj.data.query
                    this.canvasData.api = this.secondApi
                    this.canvasData.field = this.secondKey
                    this.canvasData.title = `${obj.data.name}`
                    this.canvasData.showKey = this.secondQtyKey
                    this.reloadCanvasData()
                }
            })
            this.myChart.on('dblclick', obj => {
                if (this.canvasDbClick) {
                    this.canvasDbClick(obj)
                }
            })
        },
    }
}